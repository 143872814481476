export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68')
];

export const server_loads = [];

export const dictionary = {
		"/(dashboard)": [26,[3]],
		"/(public)/404": [64,[13]],
		"/(dashboard)/activity": [27,[3,4]],
		"/(dashboard)/activity/quickbooks": [28,[3,4]],
		"/(dashboard)/activity/scheduled": [29,[3,4]],
		"/(dashboard)/announcements": [30,[3]],
		"/(backoffice)/backoffice": [16,[2]],
		"/(backoffice)/backoffice/mining": [17,[2]],
		"/(backoffice)/backoffice/mining/credits": [18,[2]],
		"/(backoffice)/backoffice/mining/workers": [19,[2]],
		"/(backoffice)/backoffice/newcustomer": [20,[2]],
		"/(backoffice)/backoffice/playground": [21,[2]],
		"/(backoffice)/backoffice/reports": [22,[2]],
		"/(backoffice)/backoffice/rsa": [23,[2]],
		"/(backoffice)/backoffice/transactions": [24,[2]],
		"/(backoffice)/backoffice/users": [25,[2]],
		"/(public)/clear": [65,[13,14]],
		"/(dashboard)/deposit": [31,[3,5]],
		"/(dashboard)/deposit/coinbase": [32,[3,5]],
		"/(public)/farewell": [66,[13]],
		"/(dashboard)/helpdesk": [33,[3]],
		"/(dashboard)/invoices": [34,[3]],
		"/(public)/invoice": [67,[13]],
		"/(login)/login": [62,[11]],
		"/(dashboard)/noaccess": [35,[3]],
		"/(dashboard)/pay": [36,[3]],
		"/(login)/register": [63,[12]],
		"/(dashboard)/request": [37,[3]],
		"/(dashboard)/settings": [38,[3,6]],
		"/(dashboard)/settings/accounts": [39,[3,6]],
		"/(dashboard)/settings/billing": [40,[3,6]],
		"/(dashboard)/settings/customers": [41,[3,6]],
		"/(dashboard)/settings/gas": [42,[3,6,7]],
		"/(dashboard)/settings/gas/details": [43,[3,6,7]],
		"/(dashboard)/settings/gateway": [44,[3,6,8]],
		"/(dashboard)/settings/gateway/fees": [45,[3,6,8]],
		"/(dashboard)/settings/gateway/gatewaysettings": [46,[3,6,8]],
		"/(dashboard)/settings/notifications": [47,[3,6]],
		"/(dashboard)/settings/personal/appearance": [48,[3,6,9]],
		"/(dashboard)/settings/personal/user": [49,[3,6,9]],
		"/(dashboard)/settings/quickbooks": [50,[3,6]],
		"/(dashboard)/settings/recipients": [51,[3,6]],
		"/(dashboard)/settings/security": [52,[3,6,10]],
		"/(dashboard)/settings/security/access": [53,[3,6,10]],
		"/(dashboard)/settings/security/activity": [54,[3,6,10]],
		"/(dashboard)/settings/security/approval": [55,[3,6,10]],
		"/(dashboard)/settings/security/devices": [56,[3,6,10]],
		"/(dashboard)/settings/transferrules": [57,[3,6]],
		"/(dashboard)/settings/users": [58,[3,6]],
		"/(dashboard)/transfer": [59,[3]],
		"/(dashboard)/uitest": [60,[3]],
		"/v0/[slug]": [68,[15]],
		"/(dashboard)/withdraw": [61,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';